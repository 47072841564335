<template>
    <dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn :to="{name: 'venue.menus.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="menus"
            :items-per-page="perPage"
            class="elevation-1"
        >
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import Menu from "@/models/Menu";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "venue-menus-index",
    components: {DashboardLayout,},
    data: function () {
        return {
            loading: false,
            menus: [],
            perPage: 15,
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Title', value: 'title'},
                {text: 'Venue', value: 'venue.name'},
                {text: 'View', value: 'venue_menu_views_count'},
                {text: 'Created at', value: 'created_at'},
            ],
        }
    },
    async mounted() {
        this.loading = true
        await this.fetchMenus()
        this.loading = false
    },

    methods: {
        async fetchMenus() {
            this.menus = await Menu
                .include(['venue', 'venue_menu_views_count'])
                .where('user', this.$auth.user().id)
                .limit(this.perPage)
                .get()
        }
    }
}
</script>

<style scoped>

</style>
